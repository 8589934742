import React from "react"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Newsletter from "../components/newsletter"
import BlogLayout from "../components/layouts/BlogLayout"
import SEO from "../components/seo"

const AuthorAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin: 0px 20px 0px 0px;
`

const NewsTitle = styled(Typography)``
const NewsBody = styled(Typography)`
  font-size: 1.2rem;
`

const NewsletterBox = styled.div`
  & hr {
    margin: 60px 0px;
  }
`
const BlogTitle = styled(Typography)`
  margin: 34px 0px 28px;
`
const BlogImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const BlogBody = styled.div`
  margin-top: 32px;
`

function capitalizeLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

export default function BlogTemplate({ data }) {
  const {
    mdx: {
      frontmatter: {
        title,
        author,
        author_img,
        img,
        date,
        dateIso,
        desc,
        path,
      },
      body,
    },
  } = data

  return (
    <BlogLayout>
      <SEO
        title={title}
        description={desc}
        path={path}
        image={img}
        date={dateIso}
        article
      />
      <BlogImage src={img} alt={title} />
      <BlogTitle variant="h4" compontent="h1">
        {title}
      </BlogTitle>
      <Box display="flex" alignItems="center" mb={4}>
        <AuthorAvatar alt={author} src={author_img} />
        <div>
          <Typography variant="subtitle1">{author}</Typography>
          <Typography variant="subtitle1">{capitalizeLetter(date)}</Typography>
        </div>
      </Box>
      <BlogBody>
        <MDXRenderer>{body}</MDXRenderer>
      </BlogBody>
      <NewsletterBox>
        <Divider />
        <NewsTitle gutterBottom align="center" variant="h4" component="h4">
          ¿Quieres aprender más sobre DeFi?
        </NewsTitle>
        <NewsBody maxWidth="600px" align="center" variant="paragraph" paragraph>
          Subscríbete ahora al newsletter y recibe semanalmente publicaciones
          sobre el movimiento DeFi. ¿Te lo vas a perder?
        </NewsBody>
        <Newsletter title={title} />
      </NewsletterBox>
    </BlogLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY", locale: "es")
        dateIso: date
        path
        title
        author
        author_img
        img
        desc
        path
      }
    }
  }
`
